import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from "react-router-dom";

// page & layout imports
import HomePage from "./pages/HomePage";
import CaseStudyPage from "./pages/CaseStudyPage";
import CategoryPage from "./pages/CategoryPage";
import SiteHeader from "./components/SiteHeader";
import SiteFooter from "./components/SiteFooter";
import CaseStudySinglePage from "./pages/CaseStudySinglePage";

const App = () => {
  return (
    <Router>
      <SiteHeader />

      <Switch>
        <Route path="/" exact>
          <HomePage></HomePage>
        </Route>

        <Route path="/work" exact>
          <CategoryPage></CategoryPage>
        </Route>

        <Route exact path="/case-studies">
          <CaseStudyPage />
        </Route>
        <Route path="/case-studies/:id">
          <CaseStudySinglePage />
        </Route>

        {/* 
        <Route path="/film" exact>
          <FilmPage></FilmPage>
        </Route>

        <Route path="/digital" exact>
          <DigitalPage></DigitalPage>
        </Route>

        <Route path="/experience" exact>
          <ExperiencesPage></ExperiencesPage>
        </Route> */}

        <Redirect to="/" />
      </Switch>

      <SiteFooter />
    </Router>
  );
};

export default App;
