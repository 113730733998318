import { useEffect, useState } from "react";

const getAllData = (expurl, filmurl, digiurl) => {
  const [dataExp, setDataExp] = useState(null);
  const [dataFilm, setDataFilm] = useState(null);
  const [dataDigi, setDataDigi] = useState(null);
  const [errorAll, setErrorAll] = useState(null);
  const [loadingAll, setLoadingAll] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingAll(true);

      try {
        const res1 = await fetch(expurl);
        const json1 = await res1.json();
        const res2 = await fetch(filmurl);
        const json2 = await res2.json();
        const res3 = await fetch(digiurl);
        const json3 = await res3.json();

        setDataExp(json1);
        setDataFilm(json2);
        setDataDigi(json3);
        setLoadingAll(false);
      } catch (error) {
        setErrorAll(error);
        setLoadingAll(false);
      }
    };
    fetchData();
  }, []);

  return { loadingAll, errorAll, dataExp, dataFilm, dataDigi };
};

export default getAllData;
