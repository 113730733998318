const addTitleTag = (text) => {
  // if (document.title) {
  //   const prevTitle = document.querySelector("title");
  //   document.head.remove(prevTitle);
  // }
  const element = document.createElement("title");
  element.text = text;
  document.head.prepend(element);
};

export default addTitleTag;
