import React from "react";
import getData from "../hooks/getData";
import getDataExtra from "../hooks/getDataExtra";

import InnerHTML from "dangerously-set-html-content";

export default function SiteFooter() {
  const { loading, error, data } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/footer?populate=*"
  );

  const { loadingextra, errorextra, dataextra } = getDataExtra(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );

  if (loading || loadingextra)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            {/* <p className="center-text">loading.......</p> */}
          </div>
        </div>
      </section>
    );

  if (error || errorextra)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            <p className="center-text">Error...</p>
          </div>
        </div>
      </section>
    );

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";

  return (
    <footer
      style={{ backgroundColor: dataextra.data.attributes.primary_color }}
    >
      <div className="container-wrap">
        <div className="row">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="item">
                <p className="add_ress"></p>
                <InnerHTML html={data.data.attributes.contact_address} />

                <p className="dir">
                  <a
                    href={data.data.attributes.direction_link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {data.data.attributes.direction_text}
                  </a>
                </p>
              </div>

              <div className="item item_logo">
                <img
                  alt="img"
                  className="ftr_logo"
                  src={
                    API_URL + data.data.attributes.pi_emblem.data.attributes.url
                  }
                />

                <a
                  className="bt_insta"
                  href={data.data.attributes.instagram}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt="img"
                    src={
                      API_URL +
                      data.data.attributes.instagram_logo.data.attributes.url
                    }
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row copy_right">
          <div className="col-12">
            <p>{data.data.attributes.copyright}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
