import React, { useEffect } from "react";
import getData from "../hooks/getData";
import getDataExtra from "../hooks/getDataExtra";
import getAllData from "../hooks/getAllData";
import { expurl, filmurl, digiurl } from "../hooks/Endpoints";
import getDataColor from "../hooks/getColorData";
import { sortByRank } from "../hooks/Utils";

import addTitleTag from "../hooks/addTitleTag";
import addLinkTag from "../hooks/addLinkTag";
import addMetaTag from "../hooks/addMetaTag";
import addMetaTagSocials from "../hooks/addMetaTagSocials";

export default function CategoryPage() {
  // useEffect(() => {
  //   window["mountSlick"]();
  //   window["mountPopup"]();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate=seo&populate[1]=seo.metaSocial&populate=seo.metaImage"
        );
        const json = await res.json();
        const seo = json.data.attributes.seo;
        //twitter
        addMetaTagSocials(
          "twitter:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("twitter:description", seo.metaSocial[1].description);
        addMetaTagSocials("twitter:title", seo.metaSocial[1].title);
        addMetaTagSocials("twitter:url", seo.canonicalURL);
        addMetaTagSocials("twitter:card", "summary_large_image");

        // facebook
        addMetaTagSocials(
          "og:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("og:description", seo.metaSocial[0].description);
        addMetaTagSocials("og:title", seo.metaSocial[0].title);
        addMetaTagSocials("og:url", seo.canonicalURL);
        addMetaTagSocials("og:type", "website");

        //general
        addLinkTag("canonical", seo.canonicalURL);

        switch (path_category) {
          case "work#featured":
            addMetaTag("title", "Pi | Featured");
            addTitleTag("Pi | Featured");
            break;
          case "work":
            addMetaTag("title", "Pi | Featured");
            addTitleTag("Pi | Featured");
            break;
          case "work#experiences":
            addMetaTag("title", "Pi | Experiences");
            addTitleTag("Pi | Experiences");

            break;
          case "work#film":
            addMetaTag("title", "Pi | Film");
            addTitleTag("Pi | Film");
            break;
          case "work#digital":
            addMetaTag("title", "Pi | Digital");
            addTitleTag("Pi | Digital");
            break;
          case "case-studies":
            addMetaTag("title", "Pi | Case-Studies");
            addTitleTag("Pi | Case-Studies");
            break;
          default:
            return null;
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  //categories
  const { loadingAll, dataExp, dataFilm, dataDigi } = getAllData(
    expurl,
    filmurl,
    digiurl
  );

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";

  const { loadingextra, dataextra } = getDataExtra(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/work?populate=*"
  );

  //featured
  const { loading, data } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/projects?filters[feature_on_latest][$eq]=true&populate=*&pagination[start]=0&pagination[limit]=9"
  );

  //color

  const { loadingColor, dataColor } = getDataColor(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );
  let path = window.location.href;
  let path_category = path.substring(path.lastIndexOf("/") + 1);

  let primary_color;
  if (!loadingColor) {
    primary_color = dataColor.data.attributes.primary_color;
  }
  if (loadingextra || loadingAll || loading || loadingColor)
    return <section className="cstm_sec category_sec"></section>;

  if (!loadingAll && !loading) {
    data.data.sort(sortByRank);
    dataExp.data.sort(sortByRank);
    dataFilm.data.sort(sortByRank);
    dataDigi.data.sort(sortByRank);
  }
  if (path_category.includes("#") || path_category == "work") {
    //console.log('----------dataextra---------')
    //console.log(dataextra.data.attributes.category1_label)
    //console.log('----------dataextra---------')

    var category_content = "";
    var content = ``;

    let cat_h2 = ``;
    let cat_h3 = ``;
    let cat_button = ``;
    // let cat_url = ``;
    let dataCategory = {};
    if (path_category == "work" || path_category == "work#featured") {
      cat_h2 = dataextra.data.attributes.feature_label;
      cat_h3 = "A selection of our newest work.";
      addMetaTag("description", "A selection of our newest work.");
      dataCategory = data;
    }
    if (path_category == "work#experiences") {
      cat_h2 = dataextra.data.attributes.category1_label;
      cat_h3 = dataextra.data.attributes.category1_opening_statement;
      addMetaTag(
        "description",
        dataextra.data.attributes.category1_opening_statement
      );

      dataCategory = dataExp;
    }
    if (path_category == "work#film") {
      cat_h2 = dataextra.data.attributes.category2_label;
      cat_h3 = dataextra.data.attributes.category2_opening_statement;
      cat_button = `Watch the Film <img src="assets/img/ico-video.svg"/>`;
      addMetaTag(
        "description",
        dataextra.data.attributes.category2_opening_statement
      );

      dataCategory = dataFilm;
    }
    if (path_category == "work#digital") {
      cat_h2 = dataextra.data.attributes.category3_label;
      cat_h3 = dataextra.data.attributes.category3_opening_statement;
      addMetaTag(
        "description",
        dataextra.data.attributes.category3_opening_statement
      );
      dataCategory = dataDigi;
    }

    content =
      content +
      `<div class="container ` +
      cat_h2 +
      `">
			<div class="row head_content">
				<div class="col-md-8">
					<h2>` +
      cat_h2 +
      `</h2>
					<h3>` +
      cat_h3 +
      `</h3>
				</div>
				<div class="col-md-4 bt_wrap">
				</div>
			</div>
			<div id="categorygrid" class="row category_grid la_test">`;

    for (var i = 0; i < dataCategory.data.length; ++i) {
      let cat_url = ``;
      content =
        content +
        `
				<div class="col-md-4 item_wrap ">
				<div class="item">
				<div class="ftrd_img">    
				`;
      for (
        var j = 0;
        j < dataCategory.data[i].attributes.image.data.length;
        ++j
      ) {
        content =
          content +
          `
					<img alt="img" src="` +
          API_URL +
          dataCategory.data[i].attributes.image.data[j].attributes.url +
          `" />`;
      }

      if (path_category == "work" || path_category == "work#featured") {
        category_content =
          `<p class="tag">` + dataCategory.data[i].attributes.category + `</p>`;
      }

      if (dataCategory.data[i].attributes.video_url != null) {
        cat_button = `Watch the Film <img src="assets/img/ico-video.svg"/>`;
      }

      if (
        dataCategory.data[i].attributes.url != null
        // || dataCategory.data[i].attributes.url_label != null
      ) {
        cat_url = dataCategory.data[i].attributes.url;
      }

      content =
        content +
        `
				</div>
				<div class="desc">
					` +
        category_content +
        `
					<h4>` +
        dataCategory.data[i].attributes.project_title +
        `</h4>
					<p>` +
        dataCategory.data[i].attributes.client_text +
        `</p>
					<p>` +
        dataCategory.data[i].attributes.description +
        `</p>
					<p class="bt_watch"><a  
          style= "--hover-color: ` +
        primary_color +
        `"
          target="_blank"
          href=` +
        dataCategory.data[i].attributes.url +
        `>` +
        cat_url +
        `</a></p>
					<p class="bt_watch "><a style= "--hover-color: ` +
        primary_color +
        `"  class="modal_video" target="_blank" href="` +
        dataCategory.data[i].attributes.video_url +
        `?autoplay=1">` +
        cat_button +
        `</a></p>
    </div>
    </div>
    </div>
    `;
      cat_button = ``;
    }
    content = content + `</div></div>`;
    let cc = document.querySelector(".cstm_sec");
    cc.innerHTML = content;
    window["mountSlick"]();
    window["mountPopup"]();
  }

  let cat_h2 = `Featured`;
  if (!loadingextra) {
    cat_h2 = dataextra.data.attributes.feature_label;
  }

  const data_img = data.data;

  //console.log(data.data[0].attributes.image.data[0].attributes.url)
  //console.log(data_img)

  // function display_img() {
  //   //console.log(data.data[0].attributes.image.data[0].attributes.url);
  //   //console.log(data.data[0].attributes.image.data[1].attributes.url);

  //   console.log(">>>>>Project Count<<<<<<< :" + data_img.length);
  //   var content = ``;

  //   for (var i = 0; i < data_img.length; ++i) {
  //     content =
  //       content +
  //       `
  //   <div class="col-md-4 item_wrap ">
  //   <div class="item">
  //   <div class="ftrd_img">
  //   `;
  //     for (var j = 0; j < data.data[i].attributes.image.data.length; ++j) {
  //       content =
  //         content +
  //         `
  //       <img alt="img" src="` +
  //         API_URL +
  //         data.data[i].attributes.image.data[j].attributes.url +
  //         `" />`;
  //     }
  //     content =
  //       content +
  //       `
  //   </div>
  //   <div class="desc">
  //       <p class="tag">` +
  //       data.data[i].attributes.category +
  //       `</p>
  //       <h4>` +
  //       data.data[i].attributes.project_title +
  //       `</h4>
  //       <p>` +
  //       data.data[i].attributes.client_text +
  //       `</p>
  //       <p>` +
  //       data.data[i].attributes.description +
  //       `</p>
  //       <p><a target="_blank" href="https://` +
  //       data.data[i].attributes.url +
  //       `">` +
  //       data.data[i].attributes.url +
  //       `</a></p>
  //   </div>
  //   </div>
  //   </div>
  //   `;
  //   }
  //   return parse(content);
  // }

  // function get_related_imgs(index) {
  //   for (var i = 0; i < data.data[index].attributes.image.data.length; i++) {
  //     console.log(data.data[index].attributes.image.data[i].attributes.url);
  //   }
  // }

  // const featuredcontent = (
  //   <div class="container">
  //     <div class="row head_content">
  //       <div class="col-md-8">
  //         <h2>{cat_h2}</h2>
  //         <h3>A selection of our newest work.</h3>
  //       </div>

  //       <div class="col-md-4 bt_wrap"></div>
  //     </div>

  //     <div class="row category_grid la_test">{display_img()}</div>
  //   </div>
  // );
  // cc.innerHTML = featuredcontent;
  // window["mountSlick"]();
  // window["mountPopup"]();

  // window["mountSlick"]();
  // window["mountPopup"]();

  return (
    <section className="cstm_sec category_sec">
      {/* <div class="container">
        <div class="row head_content">
          <div class="col-md-8">
            <h2>{cat_h2}</h2>
            <h3>A selection of our newest work.</h3>
          </div>

          <div class="col-md-4 bt_wrap"></div>
        </div>

        <div id="categorygrid" class="row category_grid la_test">
          {display_img()}
        </div>
      </div> */}
    </section>
  );
}
