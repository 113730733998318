const addMetaTagSocials = (propertyattr, content) => {
  const element = document.createElement("meta");
  element.setAttribute("property", propertyattr);

  element.content = content;
  document.head.prepend(element);
};

// tag, text, content
// tag , name, content

// <title>#####</title> ok
// <meta name="title" content="#####"> ok
// <meta name="description" content="#####"> ok
// <link rel="canonical" href="#####" /> ok

// <!-- Twitter -->
// <meta property="twitter:card" content="summary_large_image">
// <meta property="twitter:url" content="#####">
// <meta property="twitter:title" content="#####">
// <meta property="twitter:description" content="#####">
// <meta property="twitter:image" content="#####"></meta>

export default addMetaTagSocials;
