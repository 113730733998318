//import React, { Component } from "react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getData from "../hooks/getData";
import getDataExtra from "../hooks/getDataExtra";
import getDataMenu from "../hooks/getDataMenu";
import { sortByRank } from "../hooks/Utils";
import replaceTitleTag from "../hooks/replaceTitleTag";
import replaceMetaTag from "../hooks/replaceMetaTag";

const SiteHeader = (props) => {
  const [isActive, setIsActive] = useState("");
  const [caseStudyActive, setCaseStudyActive] = useState(false);
  const { loading, data } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );

  let path = window.location.href;
  let path_category = path.substring(path.lastIndexOf("/") + 1);

  useEffect(() => {
    if (path.includes("case-studies")) {
      setIsActive("a_latest");
      setCaseStudyActive(true);
    }
  }, []);
  useEffect(() => {
    switch (path_category) {
      case "work#featured":
        setIsActive("a_latest");
        break;
      case "work":
        setIsActive("a_latest");
        break;
      case "work#experiences":
        setIsActive("a_experiences");
        break;
      case "work#film":
        setIsActive("a_film");
        break;
      case "work#digital":
        setIsActive("a_digital");
        break;
      default:
        return undefined;
    }
  }, []);

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";

  /*
   * for Navs
   * ------------------------------------------
   */
  const qsMenu = API_URL + "/api/work?populate=*";
  const { loadingmenu, datamenu } = getDataMenu(qsMenu);
  let menu_expe = `Experiences`;
  let menu_film = `Film`;
  let menu_digi = `Digital`;
  let menu_feat = `Featured`;

  if (!loadingmenu) {
    menu_expe = datamenu.data.attributes.category1_label;
    menu_film = datamenu.data.attributes.category2_label;
    menu_digi = datamenu.data.attributes.category3_label;
    menu_feat = datamenu.data.attributes.feature_label;
  }

  let primary_color = ``;
  let logo_image = ``;
  if (!loading) {
    logo_image = API_URL + data.data.attributes.pi_logo.data.attributes.url;
    primary_color = data.data.attributes.primary_color;
  }

  // let isActive = "all";

  /*
    const [loadedmenu, setLoadedMenu] = useState();
	const [loadingmenu, setLoadingMenu] = useState(true)

    useEffect(() => {
        const sendRequest = async () => {
			setLoadingMenu(true)
			try {

				const res = await fetch(qsMenu)
				const json = await res.json()
				setLoadedMenu(json)
				setLoadingMenu(false)
			} catch (error) {
   
			}
        };
        sendRequest();
    }, [] )	

	if (!loadingmenu) {
		console.log('----------loadedmenu---------')
		console.log(loadedmenu.data.attributes.category1_label)
		console.log('----------loadedmenu---------')
	}
	*/

  const handleClick = (msg) => {
    let query = msg;

    let qs =
      API_URL + "/api/projects?filters[feature_on_latest][$eq]=true&populate=*";

    if (query != "all") {
      qs =
        API_URL +
        "/api/projects?filters[category][$contains]=" +
        query +
        "&populate=*";
    } else {
      qs =
        API_URL +
        "/api/projects?filters[feature_on_latest][$eq]=true&populate=*";
    }

    const { loading, data } = getData(qs);
    if (!loading) {
      data.data.sort(sortByRank);
    }

    //console.log('----------project---------')
    //console.log(data)
    //console.log('----------project---------')

    qs = API_URL + "/api/work?populate=*";
    const { loadingextra, dataextra } = getDataExtra(qs);

    if (loadingextra) return "getDataExtra...";
    //console.log('----------dataextra---------')
    //console.log(dataextra.data.attributes.category1_label)
    //console.log('----------dataextra---------')

    return function(e) {
      let work_path =
        window.location.protocol +
        "//" +
        window.location.host +
        "/work#" +
        e.target.innerText.toLowerCase();
      window.history.pushState({ path: work_path }, "Pi.ae Works", work_path);

      e.preventDefault();
      setIsActive(e.target.id);
      setCaseStudyActive("false");
      replaceTitleTag(`Pi | ${e.target.innerText}`);
      replaceMetaTag("title", `Pi | ${e.target.innerText}`);
      //var elem = document.querySelector(e.target.id)
      const ul_li = document.querySelector("ul li.nav-item");
      ul_li.classList.remove("active");
      //elem.classList.add('active');

      const ul = document.querySelectorAll("ul li");
      for (let i = 0; i <= ul.length - 1; i++) {
        ul[i].classList.remove("active");
      }

      const child = document.getElementById(e.target.id);
      child.parentElement.classList.add("active");

      //const content = `<div>`+data.data+`</div>`

      var category_content = "";
      var content = ``;
      let cat_h2 = ``;
      let cat_h3 = ``;
      let cat_button = ``;

      if (query == "all") {
        cat_h2 = dataextra.data.attributes.feature_label;
        cat_h3 = `A selection of our newest work.`;
        replaceMetaTag("description", "A selection of our newest work");
      }

      if (query == "Experiences") {
        cat_h2 = dataextra.data.attributes.category1_label;
        cat_h3 = dataextra.data.attributes.category1_opening_statement;
        replaceMetaTag(
          "description",
          dataextra.data.attributes.category1_opening_statement
        );
      }
      if (query == "Film") {
        cat_h2 = dataextra.data.attributes.category2_label;
        cat_h3 = dataextra.data.attributes.category2_opening_statement;
        cat_button = `Watch the Film <img src="assets/img/ico-video.svg"/>`;
        replaceMetaTag(
          "description",
          dataextra.data.attributes.category2_opening_statement
        );
      }
      if (query == "Digital") {
        cat_h2 = dataextra.data.attributes.category3_label;
        cat_h3 = dataextra.data.attributes.category3_opening_statement;
        replaceMetaTag(
          "description",
          dataextra.data.attributes.category3_opening_statement
        );
      }

      content =
        content +
        `<div class="container ` +
        cat_h2 +
        `">
			<div class="row head_content">
      <div class="col-md-8">
      <h2>` +
        cat_h2 +
        `</h2>
					<h3>` +
        cat_h3 +
        `</h3>
				</div>
				<div class="col-md-4 bt_wrap">
				</div>
			</div>
			<div id="categorygrid" class="row category_grid la_test">`;

      for (var i = 0; i < data.data.length; ++i) {
        let cat_url = ``;
        content =
          content +
          `
				<div class="col-md-4 item_wrap ">
				<div class="item">
				<div class="ftrd_img">    
				`;
        for (var j = 0; j < data.data[i].attributes.image.data.length; ++j) {
          content =
            content +
            `
					<img alt="img" src="` +
            API_URL +
            data.data[i].attributes.image.data[j].attributes.url +
            `" />`;
        }

        if (query != "all") {
          category_content = ``;
        } else {
          category_content =
            `<p class="tag">` + data.data[i].attributes.category + `</p>`;
        }

        if (data.data[i].attributes.video_url != null) {
          cat_button = `Watch the Film <img src="assets/img/ico-video.svg"/>`;
        }

        if (
          data.data[i].attributes.url != null
          // || data.data[i].attributes.url_label != null
        ) {
          cat_url = data.data[i].attributes.url;
        }
        content =
          content +
          `
				</div>
				<div class="desc">
					` +
          category_content +
          `
					<h4>` +
          data.data[i].attributes.project_title +
          `</h4>
					<p>` +
          data.data[i].attributes.client_text +
          `</p>
					<p>` +
          data.data[i].attributes.description +
          `</p>

					<p class="bt_watch"><a  
          style= "--hover-color: ` +
          primary_color +
          `"
          target="_blank" 
          href=` +
          data.data[i].attributes.url +
          `>` +
          cat_url +
          `</a></p>
					<p class="bt_watch "><a style= "--hover-color: ` +
          primary_color +
          `"  class="modal_video" target="_blank" href="` +
          data.data[i].attributes.video_url +
          `?autoplay=1">` +
          cat_button +
          `</a></p>
				</div>
				</div>
				</div>
				`;
        cat_button = ``;
      }
      content = content + `</div></div>`;

      //let cc = document.getElementById('categorygrid')
      let cc = document.querySelector(".cstm_sec");
      cc.innerHTML = content;
      window["mountSlick"]();
      window["mountPopup"]();
      // console.log("-------------->" + qs);
    };
  };

  const handleCaseStudy = (e) => {
    e.preventDefault();
    setCaseStudyActive("true");
    let tmp_url = window.location.href;
    const url_arr = tmp_url.split("/");
    window.location.href =
      window.location.protocol + "//" + url_arr[2] + "/case-studies";
  };

  const main_header_content = (
    <nav className="navbar navbar-expand-lg  m_header m_header_home">
      <div className="container-wrap">
        <a className="navbar-brand" href="/">
          <img className="logo" src={logo_image} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="lnr lnr-menu"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {/* <a class="nav-link" href="/category"></a> */}
        </div>
      </div>
    </nav>
  );

  const category_header_content = (
    <nav className="navbar navbar-expand-lg  m_header ">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img className="logo" src={logo_image} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="lnr lnr-menu"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto dsktop_head" id="nav_main">
            <li className="nav-item">
              <a
                id="a_latest"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == false
                      ? "#3c3c3b"
                      : primary_color,
                }}
                className="nav-link"
                onClick={handleClick(`all`)}
              >
                {menu_feat}
              </a>
            </li>

            <li className="nav-item">
              <a
                id="a_experiences"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_experiences" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Experiences`)}
              >
                {menu_expe}
              </a>
            </li>

            <li className="nav-item">
              <a
                id="a_film"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_film" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Film`)}
              >
                {menu_film}
              </a>
            </li>

            <li className="nav-item">
              <a
                id="a_digital"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_digital" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Digital`)}
              >
                {menu_digi}
              </a>
            </li>

            <li className="nav-item mb_item">
              <a
                id="a_casestudy"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == true
                      ? "#3c3c3b"
                      : primary_color,
                }}
                className="nav-link"
                onClick={handleCaseStudy}
              >
                Case Studies
              </a>
            </li>
            <li className="nav-item dsk_item">
              <a
                id="a_casestudy"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == true
                      ? "#3c3c3b"
                      : primary_color,
                }}
                onClick={handleCaseStudy}
              >
                Case Studies
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );

  const casestudy_header_content = (
    <nav className="navbar navbar-expand-lg  m_header ">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img className="logo" src={logo_image} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="lnr lnr-menu"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto dsktop_head" id="nav_main">
            <li className="nav-item">
              <a
                // href="/work"
                id="a_latest"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == false
                      ? "#3c3c3b"
                      : primary_color,
                }}
                onClick={handleClick(`all`)}
              >
                {menu_feat}
              </a>
            </li>

            <li className="nav-item">
              <a
                // href="/work"
                id="a_experiences"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_experiences" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Experiences`)}
              >
                {menu_expe}
              </a>
            </li>

            <li className="nav-item">
              <a
                // href="/work"
                id="a_film"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_film" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Film`)}
              >
                {menu_film}
              </a>
            </li>

            <li className="nav-item">
              <a
                // href="/work"
                id="a_digital"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_digital" ? "#3c3c3b" : primary_color,
                }}
                onClick={handleClick(`Digital`)}
              >
                {menu_digi}
              </a>
            </li>

            <li className="nav-item active mb_item">
              <a
                id="a_casestudy"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == true
                      ? "#3c3c3b"
                      : primary_color,
                }}
                onClick={handleCaseStudy}
              >
                Case Studies
              </a>
            </li>

            <li className="nav-item active dsk_item">
              <a
                id="a_casestudy"
                className="nav-link"
                style={{
                  backgroundColor:
                    isActive == "a_latest" && caseStudyActive == true
                      ? "#3c3c3b"
                      : primary_color,
                }}
                onClick={handleCaseStudy}
              >
                Case Studies
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );

  const location = useLocation();
  let content = "";
  if (location.pathname === "/case-studies") {
    content = casestudy_header_content;
  } else {
    content =
      location.pathname === "/" ? main_header_content : category_header_content;
  }
  //const queryParams = new URLSearchParams(window.location.search)
  //const query = queryParams.get("q")

  //console.log('query =' + query);
  //console.log('hash', location.hash);
  //console.log('pathname', location.pathname);
  //console.log('search', location.search);

  return content;
};

export default SiteHeader;
