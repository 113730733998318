import React, { useEffect, useState } from "react";
import getData from "../hooks/getData";
import getDataColor from "../hooks/getColorData";

import addTitleTag from "../hooks/addTitleTag";
import addLinkTag from "../hooks/addLinkTag";
import addMetaTag from "../hooks/addMetaTag";
import addMetaTagSocials from "../hooks/addMetaTagSocials";

export default function CategoryPage() {
  const { loading, error, data } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/case-study?populate=*"
  );

  const { loading: loadingList, error: errorList, data: dataList } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/case-study-items?populate[0]=case_study_thumbnail_graphic"
  );

  //color
  const { loadingColor, dataColor } = getDataColor(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );

  if (!loadingList) console.log(dataList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate=seo&populate[1]=seo.metaSocial&populate=seo.metaImage"
        );
        const json = await res.json();
        const seo = json.data.attributes.seo;
        //twitter
        addMetaTagSocials(
          "twitter:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("twitter:description", seo.metaSocial[1].description);
        addMetaTagSocials("twitter:title", seo.metaSocial[1].title);
        addMetaTagSocials("twitter:url", seo.canonicalURL);
        addMetaTagSocials("twitter:card", "summary_large_image");

        // facebook
        addMetaTagSocials(
          "og:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("og:description", seo.metaSocial[0].description);
        addMetaTagSocials("og:title", seo.metaSocial[0].title);
        addMetaTagSocials("og:url", seo.canonicalURL);
        addMetaTagSocials("og:type", "website");

        //general
        addLinkTag("canonical", seo.canonicalURL);

        switch (path_category) {
          case "work#featured":
            addMetaTag("title", "Pi | Featured");
            addTitleTag("Pi | Featured");
            break;
          case "work":
            addMetaTag("title", "Pi | Featured");
            addTitleTag("Pi | Featured");
            break;
          case "work#experiences":
            addMetaTag("title", "Pi | Experiences");
            addTitleTag("Pi | Experiences");

            break;
          case "work#film":
            addMetaTag("title", "Pi | Film");
            addTitleTag("Pi | Film");
            break;
          case "work#digital":
            addMetaTag("title", "Pi | Digital");
            addTitleTag("Pi | Digital");
            break;
          case "case-studies":
            addMetaTag("title", "Pi | Case-Studies");
            addTitleTag("Pi | Case-Studies");
            break;
          default:
            return null;
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";

  useEffect(() => {
    window["mountSlick"]();
    window["mountPopup"]();
  }, []);

  let path = window.location.href;
  let path_category = path.substring(path.lastIndexOf("/") + 1);

  let primary_color;
  if (!loadingColor) {
    primary_color = dataColor.data.attributes.primary_color;
  }
  if (loading || loadingList || loadingColor)
    return <section className="cstm_sec category_sec"></section>;

  return (
    <section className="cstm_sec category_sec">
      <div className={`container ` + data.data.attributes.case_study_label}>
        <div className="row head_content">
          <div className="col-md-8">
            <h2>{data.data.attributes.case_study_label}</h2>
            <h3> {data.data.attributes.case_study_opening_statement}</h3>
          </div>
          <div className="col-md-4 bt_wrap"></div>
        </div>
        <div id="categorygrid" className="row category_grid la_test">
          {dataList.data.map((item) => {
            return (
              <>
                <div className="col-md-4 item_wrap">
                  <div className="item">
                    <div className="ftrd_img">
                      <a
                        href={`/case-studies/${item.attributes.case_study_title}`}
                      >
                        <img
                          alt="img"
                          src={
                            API_URL +
                            item.attributes.case_study_thumbnail_graphic.data
                              .attributes.url
                          }
                          style={{ borderRadius: "5px" }}
                        />
                      </a>
                    </div>
                    <div className="desc">
                      <h4>
                        <a
                          style={{ textDecoration: "none", color: " #3c3c3b" }}
                          href={`/case-studies/${item.attributes.case_study_title}`}
                        >
                          {item.attributes.case_study_title}
                        </a>
                      </h4>
                      <p>{item.attributes.case_study_subheadline}</p>
                      <p>{item.attributes.case_study_excerpt}</p>

                      {(item.attributes.case_study_teaser_film != null ||
                        item.attributes.case_study_campaign_film != null) && (
                        <div className="lnk_wrap">
                          {item.attributes.case_study_teaser_film != null && (
                            <span>
                              <a
                                style={{
                                  color: "#3c3c3b",
                                }}
                                className="modal_video"
                                href={
                                  item.attributes.case_study_teaser_film +
                                  `?autoplay=1`
                                }
                              >
                                Teaser Film
                                <img src="assets/img/ico-video.svg" alt="" />
                              </a>
                            </span>
                          )}
                          {item.attributes.case_study_campaign_film != null && (
                            <span>
                              <a
                                style={{
                                  color: "#3c3c3b",
                                }}
                                className="modal_video"
                                href={
                                  item.attributes.case_study_campaign_film +
                                  `?autoplay=1`
                                }
                              >
                                Campaign Film
                                <img src="assets/img/ico-video.svg" alt="" />
                              </a>
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      {/* <div className="container">
        <div className="row head_content">
          <div className="col-md-8">
            <h2>{cat_h2}</h2>
            <h3>A selection of our newest work.</h3>
          </div>

          <div className="col-md-4 bt_wrap"></div>
        </div>

        <div id="categorygrid" className="row category_grid la_test">
          {display_img()}
        </div>
      </div> */}
    </section>
  );
}
