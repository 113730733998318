const addMetaTag = (name, content, description) => {
  const element = document.createElement("meta");

  element.name = name;
  element.content = content;
  element.description = description;

  if (name == "description") {
    const titleTag = document.head.querySelector("title");
    titleTag
      ? titleTag.parentNode.insertBefore(element, titleTag.nextSibling)
      : document.head.prepend(element);
  } else {
    document.head.prepend(element);
  }
};

// tag, text, content
// tag , name, content

// <title>#####</title> ok
// <meta name="title" content="#####"> ok
// <meta name="description" content="#####"> ok
// <link rel="canonical" href="#####" /> ok

// <!-- Open Graph / Facebook -->
// <meta property="og:type" content="website">
// <meta property="og:url" content="#####">
// <meta property="og:title" content="#####">
// <meta property="og:description" content="#####">
// <meta property="og:image" content="#####">

// <!-- Twitter -->
// <meta property="twitter:card" content="summary_large_image">
// <meta property="twitter:url" content="#####">
// <meta property="twitter:title" content="#####">
// <meta property="twitter:description" content="#####">
// <meta property="twitter:image" content="#####"></meta>

export default addMetaTag;
