import React, { useEffect } from "react";
import getData from "../hooks/getData";
import getDataExtra from "../hooks/getDataExtra";

import addTitleTag from "../hooks/addTitleTag";
import addLinkTag from "../hooks/addLinkTag";
import addMetaTag from "../hooks/addMetaTag";
import addMetaTagSocials from "../hooks/addMetaTagSocials";

export default function CaseStudySinglePage() {
  let path = window.location.href;
  let case_study_id = path.substring(path.lastIndexOf("/") + 1);
  console.log(case_study_id);
  const { loading, error, data } = getData(
    `https://phpstack-1004348-3540302.cloudwaysapps.com/api/case-study-items?filters[case_study_title][$contains]=${case_study_id}&populate=*`
  );

  if (!loading) console.log(data);
  const { loadingextra, dataextra } = getDataExtra(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate=seo&populate[1]=seo.metaSocial&populate=seo.metaImage"
        );
        const json = await res.json();
        const seo = json.data.attributes.seo;
        //twitter
        addMetaTagSocials(
          "twitter:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("twitter:description", seo.metaSocial[1].description);
        addMetaTagSocials("twitter:title", seo.metaSocial[1].title);
        addMetaTagSocials("twitter:url", seo.canonicalURL);
        addMetaTagSocials("twitter:card", "summary_large_image");

        // facebook
        addMetaTagSocials(
          "og:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("og:description", seo.metaSocial[0].description);
        addMetaTagSocials("og:title", seo.metaSocial[0].title);
        addMetaTagSocials("og:url", seo.canonicalURL);
        addMetaTagSocials("og:type", "website");

        //general
        addLinkTag("canonical", seo.canonicalURL);
        addMetaTag("title", "Pi | Case Studies");
        addTitleTag("Pi | Case Studies");
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (loading)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            {/* <p className="center-text">loading.......</p> */}
          </div>
        </div>
      </section>
    );

  // if (!loading) {
  //   addMetaTag("description", data.data.attributes.intro_message);
  // }
  if (!loading && data.data.length == 0)
    return <p>no case studies with this title</p>;
  if (error)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            <p className="center-text">Error...</p>
          </div>
        </div>
      </section>
    );
  let primary_color = "";
  if (!loadingextra) {
    primary_color = dataextra.data.attributes.primary_color;
    // console.log(primary_color);
  }

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";
  // const hero_image =
  //   API_URL + data.data.attributes.hero_unit_desktop.data.attributes.url;

  // window["mountPopup"]();

  return (
    <section className="case_study cstm_sec">
      <div className="container">
        <div className="row head_content">
          <div className="col-md-8">
            <h2>{data.data[0].attributes.case_study_title}</h2>
            <h3>{data.data[0].attributes.case_study_subheadline}</h3>
            <p>{data.data[0].attributes.case_study_description}</p>
            {(data.data[0].attributes.case_study_teaser_film != null ||
              data.data[0].attributes.case_study_campaign_film != null) && (
              <div className="lnk_wrap">
                {data.data[0].attributes.case_study_teaser_film != null && (
                  <span>
                    <a
                      style={{
                        color: "#3c3c3b",
                        "--hover-color": `${primary_color}`,
                      }}
                      className="modal_video"
                      href={
                        data.data[0].attributes.case_study_teaser_film +
                        "?autoplay=1"
                      }
                    >
                      Teaser Film
                      <img src="/assets/img/ico-video.svg" alt="" />
                    </a>
                  </span>
                )}
                {data.data[0].attributes.case_study_campaign_film != null && (
                  <span>
                    <a
                      style={{
                        color: "#3c3c3b",
                        "--hover-color": `${primary_color}`,
                      }}
                      className="modal_video"
                      href={
                        data.data[0].attributes.case_study_campaign_film +
                        "?autoplay=1"
                      }
                    >
                      Campaign Film
                      <img src="/assets/img/ico-video.svg" alt="" />
                    </a>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="col-md-4 bt_wrap"></div>
        </div>
        <div className="row img_grid">
          <div className="col-md-12">
            <img
              alt="img"
              src={
                API_URL +
                data.data[0].attributes.case_study_preview_graphic.data
                  .attributes.url
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}
