import React from "react";
import { useEffect } from "react";
import getData from "../hooks/getData";
import getDataExtra from "../hooks/getDataExtra";
import addSeo from "../hooks/addSeo";
import addTitleTag from "../hooks/addTitleTag";
import addLinkTag from "../hooks/addLinkTag";
import addMetaTag from "../hooks/addMetaTag";
import addMetaTagSocials from "../hooks/addMetaTagSocials";
import ReactMarkdown from "react-markdown";
export default function HomePage() {
  const { loading, error, data } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate=*"
  );

  const { loading: loading2, data: data2 } = getData(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate[0]=feature_card.Icon"
  );

  const { loadingextra, dataextra } = getDataExtra(
    "https://phpstack-1004348-3540302.cloudwaysapps.com/api/setting?populate=*"
  );

  const API_URL = "https://phpstack-1004348-3540302.cloudwaysapps.com";
  const logos = [];
  let data2_array = [];
  useEffect(() => {
    const script = document.createElement("script");
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://phpstack-1004348-3540302.cloudwaysapps.com/api/homepage?populate=seo&populate[1]=seo.metaSocial&populate=seo.metaImage"
        );
        const json = await res.json();
        const seo = json.data.attributes.seo;
        //twitter
        addMetaTagSocials(
          "twitter:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("twitter:description", seo.metaSocial[1].description);
        addMetaTagSocials("twitter:title", seo.metaSocial[1].title);
        addMetaTagSocials("twitter:url", seo.canonicalURL);
        addMetaTagSocials("twitter:card", "summary_large_image");

        // facebook
        addMetaTagSocials(
          "og:image",
          `https://phpstack-1004348-3540302.cloudwaysapps.com` +
            seo.metaImage.data.attributes.url
        );
        addMetaTagSocials("og:description", seo.metaSocial[0].description);
        addMetaTagSocials("og:title", seo.metaSocial[0].title);
        addMetaTagSocials("og:url", seo.canonicalURL);
        addMetaTagSocials("og:type", "website");

        //general
        addLinkTag("canonical", seo.canonicalURL);
        addMetaTag("description", seo.metaDescription);
        addMetaTag("title", seo.metaTitle);
        addTitleTag(seo.metaTitle);
        addSeo(script, JSON.stringify(seo.structuredData));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (loading)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            {/* <p className="center-text">loading.......</p> */}
          </div>
        </div>
      </section>
    );

  if (error)
    return (
      <section className="home_grid">
        <div className="container-wrap">
          <div className="row">
            <p className="center-text">Error...</p>
          </div>
        </div>
      </section>
    );
  let primary_color = "";
  if (!loadingextra) {
    primary_color = dataextra.data.attributes.primary_color;
  }

  if (!loading && !loading2) {
    data2_array = data2.data.attributes.feature_card;
    //populate logos array
    for (let i = 1; i < 33; i++) {
      logos.push(data.data.attributes[`logo_` + i]);
    }
  }

  const hero_image =
    "https://phpstack-1004348-3540302.cloudwaysapps.com" +
    data.data.attributes.hero_unit_desktop.data.attributes.url;
  const hero_tablet =
    "https://phpstack-1004348-3540302.cloudwaysapps.com" +
    data.data.attributes.hero_unit_tablet.data.attributes.url;
  const hero_mobile =
    "https://phpstack-1004348-3540302.cloudwaysapps.com" +
    data.data.attributes.hero_unit_mobile.data.attributes.url;

  const handleFeatureClick = (category) => {
    if (category != null) {
      let tmp_url = window.location.href;
      let url_arr = tmp_url.split("/");
      window.location.href =
        window.location.protocol + "//" + url_arr[2] + "/work#" + category;
    }
  };

  return (
    <section className="home_grid">
      <div className="container-wrap">
        <div className="row head_content">
          <div className="col-md-8">
            <h2>{data.data.attributes.intro_message}</h2>
          </div>

          <div className="col-md-4 bt_wrap">
            <span className="bt_work">
              <a href="/work" style={{ backgroundColor: primary_color }}>
                See our work
              </a>
            </span>
          </div>
        </div>

        <div className="row img_grid">
          <div className="col-md-12">
            <a href="/work">
              <img className="dsk_top" alt="img" src={hero_image} />
              <img className="tab_let hidden" alt="img" src={hero_tablet} />
              <img className="mobi_le hidden" alt="img" src={hero_mobile} />
            </a>
          </div>
        </div>

        <div className="row logo_intro">
          <div className="col-md-12">
            <div className="wrap">
              <h2>{data.data.attributes.about_headline}</h2>
              <p>{data.data.attributes.about_desc}</p>
            </div>
          </div>
        </div>

        <div className="row feature_bar">
          {data2_array.map((feature, index) => {
            if (feature.URL == "Strategy")
              return (
                <div
                  key={index}
                  className="col-md-3 item_col"
                  style={{ cursor: "default" }}
                >
                  <div className="item">
                    <img
                      src={API_URL + feature.Icon.data.attributes.url}
                      alt="img"
                    ></img>
                    <ReactMarkdown>{feature.Description}</ReactMarkdown>
                  </div>
                </div>
              );
            else {
              return (
                <div
                  key={index}
                  className="col-md-3 item_col"
                  onClick={() => handleFeatureClick(feature.URL)}
                >
                  <div className="item">
                    <img
                      src={API_URL + feature.Icon.data.attributes.url}
                      alt="img"
                    ></img>
                    <ReactMarkdown>{feature.Description}</ReactMarkdown>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div className="row logo_grid">
          <div className="col-md-12">
            <div className="logo_wrapper">
              {logos.map((logo, index) => {
                return (
                  <div key={index} className="item">
                    <img alt="img" src={API_URL + logo.data.attributes.url} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
