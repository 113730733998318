import { useEffect, useState } from "react";

const getDataExtra = (url) => {
  const [dataextra, setData] = useState(null);
  const [errorextra, setError] = useState(null);
  const [loadingextra, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await fetch(url);
        const json = await res.json();

        setData(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);

  return { loadingextra, errorextra, dataextra };
};

export default getDataExtra;
